$(document).on('load turbolinks:load', function() {

    I18n.translations["xx"] = I18n.extend((I18n.translations["xx"] || {}), {
            date: {
                formats: {
                    default: "%d. %b %y",
                    short: "%e. %b %y",
                    long: "%e. %B %Y,",
                    only_day: "%e",
                    numbers: "%d.%m.%Y",
                    dow_day_month: "%a %d. %b",
                    long_w_dow: "%a %d.%m.%Y",
                    short_d_m: "%a %d.%m.",
                    daterangepicker: "DD/MM/YYYY"
                }
            }

        }
    )

    const getI18nDateFormat = (key) => {
        const currentLocale = I18n.locale;
        const format = I18n.t(key, { locale: currentLocale, defaultValue: null });
        if (!format.includes('missing')) {
            return format;
        }
        return I18n.t(key, { locale: 'xx' });
    };

    const enforceLatinDigits = (locale) => {
        const originalLocaleData = moment.localeData(locale);
        const modifiedLocaleData = {
            ...originalLocaleData._config,
            postformat: (str) => str.replace(/\d/g, (digit) => '0123456789'[digit])
        };
        moment.updateLocale(locale, modifiedLocaleData);
    };

    moment.locale(I18n.locale);
    enforceLatinDigits(I18n.locale);

    const daterangeElement = $('input[name="daterange"], #daterange');
    const today = daterangeElement.data('daterangepicker-today') || getI18nDateFormat('daterangepicker.today');
    const yesterday = daterangeElement.data('daterangepicker-yesterday') || getI18nDateFormat('daterangepicker.yesterday');
    const last_7_days = daterangeElement.data('daterangepicker-last7d') || getI18nDateFormat('daterangepicker.last_7_days');
    const last_30_Days = daterangeElement.data('daterangepicker-last30d') || getI18nDateFormat('daterangepicker.last_30_Days');
    const this_month = daterangeElement.data('daterangepicker-thismonth') || getI18nDateFormat('daterangepicker.this_month');
    const last_month = daterangeElement.data('daterangepicker-lastmonth') || getI18nDateFormat('daterangepicker.last_month');
    const custom_range = daterangeElement.data('daterangepicker-customrange') || getI18nDateFormat('daterangepicker.custom_range');
    const clear = daterangeElement.data('daterangepicker-clear') || getI18nDateFormat('daterangepicker.clear');
    const apply = daterangeElement.data('daterangepicker-apply') || getI18nDateFormat('daterangepicker.apply');
    const dateFormat = getI18nDateFormat('date.formats.daterangepicker');

    daterangeElement.daterangepicker({
        locale: {
            format: dateFormat,
            cancelLabel: clear,
            applyLabel: apply,
            customRangeLabel: custom_range
        },
        ranges: {
            [today]: [moment(), moment()],
            [yesterday]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            [last_7_days]: [moment().subtract(6, 'days'), moment()],
            [last_30_Days]: [moment().subtract(29, 'days'), moment()],
            [this_month]: [moment().startOf('month'), moment().endOf('month')],
            [last_month]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        alwaysShowCalendars: false,
        showDropdowns: true,
        startDate: daterangeElement.data('startDate') || moment().subtract(29, 'days'),
        endDate: daterangeElement.data('endDate') || moment(),
        maxDate: moment(),
        opens: 'center',
        cancelButtonClasses: 'cancelBtn btn btn-sm btn-outline-primary'

    }, function(start, end, label) {
        console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
    });

    daterangeElement.on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
        $(this).hasClass('notempty') ? $(this).val('') : '';
    });

    $('#daterange').on('apply.daterangepicker', function(ev, picker) {
        !($(this).hasClass('notempty')) ? $(this).addClass('notempty') : '';
    });

    if (daterangeElement.length > 0 && !daterangeElement.data('autoUpdateInput')) {
        $('input[name="daterange"], #daterange').on('apply.daterangepicker', function (ev, picker) {
            $(this).val(picker.startDate.format(dateFormat) + ' - ' + picker.endDate.format(dateFormat));
        });

        const startDate = daterangeElement.data('daterangepicker').element[0].dataset.startDate;
        const endDate = daterangeElement.data('daterangepicker').element[0].dataset.endDate;
        if (startDate && endDate) {
            daterangeElement.val(startDate + ' - ' + endDate);
        }
    }
});
